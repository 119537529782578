import { useStaticQuery, graphql } from "gatsby";
import { SiteSiteMetadata } from '~/graphql';

export const apiSiteMetaQuery = (): SiteSiteMetadata => {
  const query = (): any => useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  return query()?.site.siteMetadata;
}